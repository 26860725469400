@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

/*===== Fonts =====*/
:root{
  --body-font: 'Inter', sans-serif;
  --big-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: 0.938rem;
}

/*==== Colors =====*/

:root{
  --first-color: #777CD7;
  --second-color: #FF5A09;
  --third-color: #298CB3;
  --fourth-color: #EA4D56;
  --fifth-color: #4DB35E;
  
  --text-color: #322924;
  --text-body-color: #5C5A58;
  --text-title-color: #120000;
  --text-normal-color: #000000;
}

/*==== Color Avatar =====*/
.first-color {
  background-color: var(--first-color)
}
.second-color {
  background-color: var(--second-color)
}
.third-color {
  background-color: var(--third-color)
}
.fourth-color {
  background-color: var(--fourth-color)
}
.fifth-color {
  background-color: var(--fifth-color)
}

@media screen and (min-width: 1024px){
  :root{
    --big-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 1.2rem;
    --useful-font-size: 1.1rem;
    --medium-font-size: 1.3rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  :root{
    --big-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 1rem;
    --useful-font-size: 1.1rem;
    --medium-font-size: 1.3rem;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  :root{
    --big-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 0.9rem;
    --useful-font-size: 1.1rem;
    --medium-font-size: 1.3rem;
  }
}

@media screen and (min-width: 399px) and (max-width: 480px) {
  :root{
    --big-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 0.9rem;
    --useful-font-size: 1.1rem;
    --medium-font-size: 1.3rem;
  }
}

@media screen and (max-width: 399px) {
  :root{
    --big-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 14px;
    --useful-font-size: 1.1rem;
    --medium-font-size: 1.3rem;
  }
}


/*===== Margins =====*/
:root{
  --mb-0: -1rem;
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  --mb-7: 5rem;
}

/*===== Paddings =====*/
:root{
  --pd: 0.5rem;
  --pd-1: 0.75rem;
  --pd-2: 1rem;
  --pd-3: 1.5rem;
  --pd-4: 2rem;
  --pd-5: 2.5rem;
  --pd-6: 3rem;
  --pd-7: 6rem;
}

/*===== z index =====*/
:root{
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*===== BASE =====*/
*,::before,::after{
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--text-body-color);
}

a[href^="mailto:"] {
  color: #ffffff;
}

.title {
  color: var(--text-title-color);
}

.page-text {
  color: var(--text-normal-color);
  line-height: 132.02%;
}

/*===== Navar =====*/

#navbar{
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--pd-3);
  background-color: #d1d1d15e;
}

#navbar ul {
  display: flex;
  gap: 4rem;
}

.btn{
  padding: var(--pd-1) var(--pd-6);
  background: var(--first-color);
  font-size: var(--normal-font-size);
  color: var(--second-color); 
  border: 1px solid var(--first-color);
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover{
  background: var(--fourth-color);
}

#navlist{
  /* padding-top: var(--pd); */
  margin-right: var(--mb-1);
}

.link{
  text-decoration: none;
  font-size: var(--normal-font-size);
  color: var(--text-color);
  font-weight: 700;
}

.link:hover{
  color: var(--first-color);
}

.menu-nav{
  background-color: #3A302E;
  width: 100%;
  height: 79px;
  position: absolute;
  top: 0;
  z-index: 50;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--pd-3);
  padding-top: 15px;
}


.menu-style {
  background-color: #3A302E;
  width: 100%;
  height: 680px;
  position: absolute;
  top: 81px;
  z-index: 50;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: none;
  cursor: pointer;
  outline: none;
  align-items: flex-end;
}

.link-menu{
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  padding: 3rem 2rem 0 0;
  font-size: 25px;
}

/*===== Home =====*/

main{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.textContainer h2{
  color: var(--first-color);
  font-weight: bold;
}

.textContainer h1{
  font-size: var(--big-font-size);
  line-height: 1.2;
  font-weight: bolder;
  margin: 0;
}

.textContainer p{
  font-size: var(--medium-font-size);
  width: 550px;
}

.text-top-block h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 29px;
}

.text-top-block h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.img-logo {
  width: 115px;
}

.banner {
  position: relative;
  background-color: #805B50;
}

.title-block {
  color: #120000;
  font-size: 21px;
  font-weight: 600;
}


.convenience{
  display: flex;
  justify-content: center;
}

.contentBlock {
  width: 450px;
  margin-left: 15px;
  text-align: left;
  line-height: 20px;
  color: #5C5A58;
}

.round-avatar{
  margin-top: 15px;
  border-radius: 50%;
  display: flex;
  width: 90px;
  height: 90px;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.avatar-girl {
  margin-top: 15px;
  border-radius: 50%;
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.imageBanner {
  width: 100%;
}


/*==== BannerText =====*/

.textContainer {
  background-color: #39322ccf;
  padding: 15px;
  height: auto; 
  position: absolute;
  top: 673px;
  right: 0;
}

.text-banner {
  color: #ffffffb0;
  font-size: 57px;
}

.text-banner2 {
  color: #FF7D55;
  font-size: 57px;
}


/*==== TextTop =====*/

.text-top-block {
  height: auto;
  background-color: #298CB3;
  color: #fff;
  padding: 5px 25px 15px 20px;
  width: 100%;
}

.section1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 25px;
}

.maneken {
  width: 90%;
  padding: 0 100px;
}


.girl {
  width: 80%;
}

.block-girl {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  text-align: right;
}

.section3 {
  position: relative;
}


.section4 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 25px 25px 25px;
  position: relative;
}

.dress {
  width: 30%;
}

.SustainabilityBlock {
  position: relative;
  padding-top: 100px;
}

.sustainability-width {
  width: 350px;
}

.dress2 {
  width: 80%;
}

.dress3 {
  width: 50%;
  /* margin-left: 25px; */
  padding-right: 15px;
}


.section5 {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: flex-start;
}

.onfooter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 90%;
}


.organize {
  display: flex;
  justify-content: flex-end;
}

.organize-width {
  width: 450px;
}



/*==== Footer =====*/

.footer-home {
  display: flex;
  height: 200px;
  justify-content: space-between;
  align-items: center;
  padding: 250px 0px 20px 0px;
}

.footer-link {
  display: flex;
  flex-direction: column-reverse;
  height: auto;
  margin-bottom: 150px;
}

.footer-img {
  width: 30%;
}


/*==== Legal Page =====*/

.contentLegal {
  padding: 25px;
}

.legalText {
  width: 100%;
}

.top-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.cat-style {
  padding-right: 15px;
  padding-top: 15px;
  width: 250px;
}


.footer-legal{
  display: flex;
  height: 200px;
  justify-content: space-between;
  align-items: center;
  padding: 190px 0px 20px 20px;
}


/*==== Partnershipt Page =====*/

.contentText {
  padding: 15px 25px 70px 25px;
}

.forma {
  text-align: left;
  padding: 0 45px;
  max-width: 550px;
  margin: auto;
  margin-top: 50px;
}

.label-style {
  color: #000;
  font-weight: 700;
}

.input-style {
  border-radius: 10px;
  border: 1px solid #393939;
  padding: 10px;
  margin-top: 5px;
  width: 100%;
}

.button-sub {
  width: 100%;
  background-color: #26CF77;
  color: #fff;
  border-radius: 10px;
  border: 0px;
  padding: 9px;
  font-size: 20px;
  margin-top: 20px;
}

.footer-partnershipt{
  display: flex;
  height: 200px;
  justify-content: space-between;
  align-items: center;
  padding: 200px 0px 20px 0px;
}