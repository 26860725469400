
/* monitors and laptops */
@media (min-width: 1240px)  {
  .menu-icon {
    display:none;
  }
  #navbar ul {
    display: flex;
  }

  .menu-mobile {
    display: none;
  }

  .banner {
    height: 560px;
    position: relative;
  }

  .imageBanner1 {
    display: none;
  }

  .imageBanner2 {
    display: none;
  }
  
  .imageBanner3 {
    width: 100%;
  }

  /* .mainImg img {
    margin-top: -35px;
    margin-right: var(--mb-2);
  } */

  .imgFox {
    width: 220px;
    position: absolute;
    top: 140px;
    right: 270px;
  }
  
  .imgIphone {
    width: 230px;
    position: absolute;
    top: 40px;
    right: 50px;
  }

  .textContainer {
    padding: 3px;
    position: absolute;
    top: 500px;
    right: 0;
    width: 850px;
  }
  .text-banner {
    font-size: 37px;
  }

  .text-banner2 {
    font-size: 37px;
  }

  .text-top-block {
    padding: 5px 5px 15px 20px;
  }

  .section1{
    align-items: flex-start;
    flex-direction: row;
  }

  .convenience-width {
    width: 400px;
  }

  .section2 {
    margin-top: -300px;
  }

  .fon{
    display: none;
  }

  .girl {
    width: 80%;
    padding: 0 20px;
  }


  .cat {
    width: 10%;
  }

  .personalization-width {
    width: 450px;
    margin-right: 100px;
  }

  .avatar-girl {
    display: none;
  }

  .avatar-sustain {
    display: none;
  }

  .dress3 {
    width: 25%;
    margin-left: 25px;
    margin-top: 150px;
  }

  .onfooter {
    display: block;
    text-align: right;
    margin-top: -150px;
  }

  .onfooter2 {
    display: none;
  }

  .footer-img {
    display: block;
    height: auto;
    width: 30%;
  }

  .footer-cat{
    display: none;
  }
}



/*----------------------------------------------------------*/

@media (min-width: 1024px) and (max-width: 1240px)  {

  .menu-icon {
    display:none;
  }

  .menu-mobile {
    display: none;
  }

  #navbar ul {
    display: flex;
  }

  .banner {
    background-color: #805850;
    height: 400px;
  }

  .imageBanner1 {
    display: none;
  }

  .imageBanner2 {
    display: none;
  }
  
  .imageBanner3 {
    display: block;
    width: 100%;
    height: 400px;
  }


  .imgFox {
    width: 190px;
    position: absolute;
    top: 50px;
    right: 210px;
  }

  .imgIphone {
    width: 170px;
    position: absolute;
    top: 20px;
    right: 50px;
  }

  .textContainer {
    padding: 3px;
    position: absolute;
    top: 355px;
    right: 0;
    width: 520px;
  }
  .text-banner {
    font-size: 28px;
  }

  .text-banner2 {
    font-size: 25px;
  }

  .section1 {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding: 25px;
  }

  .convenience-width {
    width: 400px;
  }

  .section2 {
    margin-top: -150px;
  }

  .fon{
    display: none;
  }

  .cat {
    width: 12%;
  }

  .block-girl {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    margin-left: -80px;
  }

  .girl {
    width: 80%;
    padding: 0 50px;
  }

  .avatar-girl {
    display: none;
    position: absolute;
    top: 160px;
    right: 520px;
  }

  .dress {
    width: 45%;
  }

  .avatar-sustain {
    display: none;
  }

  .dress3 {
    width: 30%;
    margin-left: 25px;
    margin-top: 150px;
  }

  .onfooter {
    display: block;
    text-align: right;
    margin-top: -100px;
  }

  .onfooter2 {
    display: none;
  }

  .footer-img {
    display: block;
    height: auto;
    width: 30%;
  }


  .footer-cat{
    display: none;
  }

}



/*-------------------------------------------------------*/
/* tablet */
@media (min-width: 768px) and (max-width: 1024px) {

  .menu-icon {
    display:none;
  }

  .menu-mobile {
    display: none;
  }

  #navbar ul {
    display: flex;
  }

  .banner {
    height: 526px;
  }

  .imgFox {
    width: 250px;
    position: absolute;
    top: 80px;
    right: 260px;
  }

  .imgIphone {
    width: 230px;
    position: absolute;
    top: 25px;
    right: 40px;
  }

  .textContainer p{
    width: auto;
  }

  .textContainer { 
    padding: 5px;
    position: absolute;
    top: 482px;
    right: 0;
    width: 650px;
  }

  .text-banner {
    font-size: 30px;
    line-height: 29px;
  }
  
  .text-banner2 {
    font-size: 30px;
  }

  .imageBanner1 {
    display: block;
    width: 100%;
    height: 526px;
  }

  .imageBanner2 {
    display: none;
  }
  
  .imageBanner3 {
    display: none;
  }

  .img-logo {
    width: 100px;
    padding: 15px 0;
  }

  .menu-icon {
    width: 25px;
    height: 25px;
    z-index: 100;
  }

  .section1 {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    padding: 25px;
  }

  .convenience-width {
    width: 350px;
  }

  .maneken {
    padding: 0 25px;
  }

  .section2 {
    margin-top: -150px;
  }

  .fon{
    display: none;
  }

  .cat {
    width: 15%;
  }

  .block-girl {
    text-align: right;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
  }

  .girl {
    padding: 0;
  }

  .avatar-girl {
    display: none;
  }

  .variety-width {
    width: 350px;
  }

  .avatar-sustain {
    display: none;
  }

  .SustainabilityBlock {
    position: relative;
    padding-top: 20px;
  }

  .sustainability-width {
    width: 267px;
  }

  .dress {
    width: 50%;
  }

  .dress2 {
    width: 70%;
  }

  .dress3 {
    width: 30%;
  }

  .onfooter {
    display: block;
    text-align: right;
    margin-top: -100px;
  }

  .onfooter2 {
    display: none;
  }


  .footer-img {
    display: block;
    height: auto;
    width: 30%;
  }

  .link-app {
    width: 100%;
    padding-bottom: 15px;
  }


  .footer-cat{
    display: none;
  }

}



/* mobile */
/* ------------------------------------------------- */

@media (min-width: 480px) and (max-width: 768px) {

  .menu-icon {
    display:block;
  }

  #navbar {
    padding-top: 15px;
  }

  #navbar ul {
    display: none;
  }

  .banner {
    height: 670px;
  }
    
  .imgFox {
    width: 210px;
    position: absolute;
    top: 180px;
    right: 280px;
  }

  .imgIphone {
    width: 260px;
    position: absolute;
    top: 40px;
    right: 30px;
  }

  .textContainer {
    padding: 15px;
    height: auto;
    position: absolute;
    top: 550px;
    right: 0;
    width: 450px;
  }

  .text-banner {
    font-size: 33px;
  }
  
  .text-banner2 {
    font-size: 33px;
  }

  .imageBanner1 {
    display: block;
    width: 100%;
  }
    
  .imageBanner2 {
    display: none;
  }

  .imageBanner3 {
    display: none;
  }

  .round-avatar {
    width: 70px;
    height: 70px;
  }

  .convenience-width {
    width: 350px;
  }

  .convenience{
    display: flex;
    justify-content: end;
    margin-right: 25px;
  }

  .section1 {
    position: relative;
  }

  .fon{
    display: block;
    width: 100%;
  }

  .maneken {
    width: 100%;
  }

  .cat {
    width: 20%;
  }

  .conv {
    width: 250px;
  }

  .block-girl {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    text-align: right;
    margin-top: -110%;
  }

  .girl {
    width: 100%;
    padding: 0;
  }

  .no-avatar {
    display: none;
  }

  .girl-avatar {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .avatar-girl {
    display: flex;
    position: absolute;
    bottom: 50px;
    margin-right: 10px;
  }


  .section3 {
    display: block;
  }

  .dress {
    margin-top: 20px;
    width: 90%;
  }

  .variety-width {
    width: 350px;
  }

  .avatar-sustain {
    position: absolute;
    right: 25px;
    top: 15px;
  }

  .sustain-title {
    text-align: right;
  }

  .sustainability-width {
    width: 450px;
    text-align: right;
  }

  .section4 {
    flex-direction: column;
  }

  .dress2 {
    width: 60%;
  }

  .dress3 {
    width: 60%;
  }

  .section5 {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-top: -240px;
  }

  .SustainabilityBlock {
    margin-top: -195%;
  }

  .onfooter {
    display: none;
  }

  .onfooter2 {
    display: block;
    margin-top: 150px;
    text-align: right;
    padding-right: 15px;
  }

  .organize-width {
    width:  300px;
  }

  .footer {
    height: 100px;
    padding: 850px 20px 20px 20px;
  }

  .footer-link {
    width: 40%;
    padding: 30px;
    margin-bottom: 50px;
  }

  .link-app {
    width: 160%;
    padding-bottom: 15px;
  }

  .footer-img {
    display: none;
  }

  .footer-cat {
    display: block;
    width: 45%;
  }
}




/* mobile */
/*----------------------------------------------------------------*/

@media (min-width : 399px) and (max-width : 480px) {

  .img-logo {
    width: 100px;
    padding: 15px 0;
  }

  .menu-icon {
    display:block;
  }

  #navbar {
    padding-top: 15px;
  }

  #navbar ul {
    display: none;
  }

  .banner {
    height: 550px;
  }
    
  .imgFox {
    width: 180px;
    position: absolute;
    top: 130px;
    right: 220px;
  }

  .imgIphone {
    width: 210px;
    position: absolute;
    top: 40px;
    right: 20px;
  }

  .textContainer {
    padding: 15px;
    height: auto;
    position: absolute;
    top: 450px;
    right: 0;
    width: 350px;
  }

  .text-banner {
    font-size: 27px;
  }
  
  .text-banner2 {
    font-size: 27px;
  }
  

  .imageBanner1 {
    display: none;
  }
    
  .imageBanner2 {
    display: block;
    /* height: 410px; */
    width: 100%;
  }

  .imageBanner3 {
    display: none;
  }

  .text-top-block {
    padding: 5px 30px 35px 30px;
  }

  .section1 {
    position: relative;
    align-items: center;
  }

  .fon{
    width: 100%;
    display: block;
  }

  .round-avatar {
    margin-top: 15px;
    width: 60px;
    height: 60px;
  }
  .convenience {
    justify-content: flex-end;
    padding-right: 15px;
  }

  .convenience-width {
    width: 80%;
  }

  .maneken {
    width: 100%;
    padding: 0 50px;
  }

  .block-girl {
    display: flex;
    flex-direction: row;
    margin-top: -110%;
  }

  .conv {
    width: 250px;
  }

  .girl {
    width: 100%;
    padding: 0;
  }

  .no-avatar {
    display: none;
  }

  .girl-avatar {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .avatar-girl {
    display: flex;
    position: absolute;
    bottom: 50px;
    margin-right: 12px;
  }

  .personalization-width {
    width: 150px;
  }
  

  .section3 {
    display: block;
  }

  .section4 {
    justify-content: space-between;
    flex-direction: column;
  }

  .dress {
    margin-top: 20px;
    width: 80%;
  }

  .variety-width {
    width: 250px;
  }

  .section5 {
    flex-direction: column;
    align-items: flex-end;
    margin-top: -200px;
  }

  .sustainability-width {
    width: 350px;
    text-align: right;
  }

  .SustainabilityBlock {
    margin-top: -200%;
  }

  .avatar-sustain {
    position: absolute;
    right: 15px;
    top: 30px;
  }

  .sustain-title {
    text-align: right;
  }

  .dress2 {
    width: 80%;
  }

  .organize {
    justify-content: flex-start;
    margin-left: 15px;
  }

  .organize-width {
    width: 250px;
  }

  .cat {
    width: 30%;
  }

  .onfooter {
    display: none;
  }

  .onfooter2 {
    display: block;
    margin-top: 120px;
    text-align: right;
    padding-right: 15px;
  }

  .footer {
    height: 100px;
  }

  .footer-home {
    padding: 150px 0px 20px 20px;
  }

  .footer-link {
    margin-bottom: 50px;
    width: 30%;
  }

  .link-app {
    padding-bottom: 15px;
    width: 200%;
  }

  .footer-img {
    display: none;
  }

  .footer-cat{
    display: block;
    width: 40%;
  }

}

/*------------------------------------------------*/

@media (min-width : 320px) and (max-width : 399px) {
  .img-logo {
    width: 100px;
    padding: 15px 0;
  }

  .menu-icon {
    display:block;
  }

  #navbar {
    padding-top: 15px;
  }

  #navbar ul {
    display: none;
  }

  .banner {
    height: 550px;
  }
    
  .imgFox {
    width: 110px;
    position: absolute;
    top: 210px;
    right: 200px;
  }

  .imgIphone {
    width: 200px;
    position: absolute;
    top: 40px;
    right: 10px;
  }

  .textContainer {
    padding: 15px;
    height: auto;
    position: absolute;
    top: 450px;
    right: 0;
    width: 300px;
  }

  .text-banner {
    font-size: 22px;
  }
  
  .text-banner2 {
    font-size: 22px;
  }

  .text-top-block h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
  }
  
  .text-top-block h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }
  

  .imageBanner1 {
    display: none;
  }
    
  .imageBanner2 {
    display: block;
    width: 100%;
  }

  .imageBanner3 {
    display: none;
  }

  .text-top-block {
    padding: 5px 30px 35px 30px;
  }

  .section1 {
    position: relative;
    align-items: center;
  }

  .fon{
    width: 100%;
    display: block;
  }

  .round-avatar {
    margin-top: 15px;
    width: 50px;
    height: 50px;
  }
  .convenience {
    justify-content: flex-end;
    padding-right: 15px;
  }

  .convenience-width {
    width: 75%;
  }

  .maneken {
    width: 100%;
    padding: 0 20px;
  }

  .block-girl {
    display: flex;
    flex-direction: row;
    margin-top: -120%;
  }

  .conv {
    width: 250px;
  }

  .girl {
    width: 100%;
    padding: 0;
  }

  .no-avatar {
    display: none;
  }

  .girl-avatar {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .avatar-girl {
    display: flex;
    position: absolute;
    bottom: 20px;
    margin-right: 5px;
  }


  .personalization-width {
    width: 150px;
  }
  

  .section3 {
    display: block;
  }

  .section4 {
    justify-content: space-between;
    flex-direction: column;
  }

  .dress {
    margin-top: 20px;
    width: 80%;
  }

  .variety-width {
    width: 190px;
  }

  .section5 {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-top: -200px;
  }

  .sustainability-width {
    width: 250px;
    text-align: right;
  }

  .SustainabilityBlock {
    margin-top: -220%;
  }

  .avatar-sustain {
    position: absolute;
    right: 15px;
    top: 30px;
  }

  .sustain-title {
    text-align: right;
  }

  .dress2 {
    width: 80%;
  }

  .organize {
    justify-content: flex-start;
    margin-left: 15px;
  }

  .organize-width {
    width: 190px;
  }

  .cat {
    width: 30%;
  }

  .onfooter {
    display: none;
  }

  .onfooter2 {
    display: block;
    margin-top: 80px;
    text-align: right;
    padding-right: 15px;
  }

  .footer {
    height: 100px;
  }

  .footer-home {
    padding: 150px 0px 20px 20px;
  }

  .footer-link {
    margin-bottom: 50px;
    width: 60%;
    height: 120px;
  }

  .link-app {
    padding-bottom: 5px;
    width: 100%;
  }

  .footer-img {
    display: none;
  }

  .footer-cat{
    display: block;
    width: 40%;
  }
}
